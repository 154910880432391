/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import { themeStyles } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import ThemeContext from '../../utils/theme-context';

export default function ErrorPage() {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);

  const closeModal = () => {
    localStorage.removeItem('lead');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
    navigate('/');
  };

  return (
    <Container id="ast-error" className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {/* <div className={classes.leadFormHelperBar}>
          <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2">Oops! Something went wrong, please try again later.</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <Button id="ast-error-close-btn" onClick={closeModal} variant="contained" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} size="large">Close</Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
