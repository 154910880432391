import React from 'react';

const astContextValue = {};

export const AstContext = React.createContext();
export const AstContextDispatch = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CUSTOMER_DETAILS': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        customerInfo: action.value
      };
    }
    case 'SET_BUY_SELL': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        operationType: action.value
      };
    }
    case 'SET_BUY_LOCATION': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        buyCity: action.value.buyingLocation.city,
        buyState: action.value.buyingLocation.state
      };
    }
    case 'SET_SELL_LOCATION': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        sellAddress: action.value.sellingLocation.address,
        sellCity: action.value.sellingLocation.city,
        sellState: action.value.sellingLocation.state,
        sellZipcode: action.value.sellingLocation.zip
      };
    }
    case 'SET_BUY_PROPERTY_TYPE': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        buyPropTypeCode: action.value.buyPropTypeCode
      };
    }
    case 'SET_PRICE_RANGE': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        buyMinPrice: action.value.buyMinPrice,
        buyMaxPrice: action.value.buyMaxPrice
      };
    }
    case 'SET_SELL_PROPERTY_VALUE': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        sellPropPrice: action.value.sellPropPrice
      };
    }
    case 'SET_OFF_RAMP_FLAG': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isOffRamp: action.value.isOffRamp
      };
    }
    case 'SET_LENDER': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isLender: action.value.isLender
      };
    }
    case 'SET_SPECIFIC_DATES': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isSpecificDates: action.value.isSpecificDates
      };
    }
    case 'SET_MORTGAGE_PREAPPROVAL': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isPreapproved: action.value.isPreapproved
      };
    }
    case 'SET_REAL_ESTATE_AGENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        hasAgent: action.value.hasAgent
      };
    }
    case 'SET_SELECTED_AGENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('lead')),
        broker: action.value.broker,
        sellingComments: action.value.sellingComments,
        buyingComments: action.value.buyingComments,
        signatures: action.value.signatures
      };
    }
    case 'SET_BUYING_AGENT_COMMENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('lead')),
        buyingComments: action.value.buyingComments
      };
    }
    case 'SET_SELLING_AGENT_COMMENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('lead')),
        sellingComments: action.value.sellingComments
      };
    }
    case 'SET_BUYING_SELLING_AGENT_COMMENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('lead')),
        buysellComments: action.value.buysellComments
      };
    }
    case 'CLEAR_AGENT_COMMENT': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('lead')),
        sellingComments: '',
        buyingComments: '',
        buysellComments: ''
      };
    }

    case 'SET_ESIGN': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isEsign: action.value.isEsign
      };
    }
    case 'SET_ABAD': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state,
        isABAD: action.value.isABAD
      };
    }
    case 'SET_RESIDENCE_DISCLAIMER': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state, stateOfResd: action.value.stateOfResd
      };
    }
    case 'SET_BUY_TIMEFRAME': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state, buyMoveTimeframe: action.value.buyMoveTimeframe
      };
    }
    case 'SET_LIST_TIMEFRAME': {
      localStorage.setItem('lead', JSON.stringify(action.value));
      return {
        ...state, sellMoveTimeframe: action.value.sellMoveTimeframe
      };
    }
    default:
      throw new Error('Bad Action Type');
  }
}

const AstContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, astContextValue);
  return (
    <AstContext.Provider value={state}>
      <AstContextDispatch.Provider value={dispatch}>
        {children}
      </AstContextDispatch.Provider>
    </AstContext.Provider>
  );
};

export default AstContextProvider;
