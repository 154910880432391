/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const postOrUpdateContactInfo = async (values, status, url, clientDetail, contact) => {
  const contactId = contact ? contact : localStorage.getItem('mongoContactId');
  const clientNameValue = clientDetail ? clientDetail?.clientName : null;
  const clientNumberValue = clientDetail ? clientDetail?.clientNumber : null;
  const customerInfoSubmit = {
    status,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneType: 'Mobile',
    primaryPhoneNo: values.phoneNumber,
    textOptIn: values.textYou,
    clientNumber: clientNumberValue,
    clientName: clientNameValue,
    contactId: contactId || ''
  };
  // eslint-disable-next-line no-useless-catch
  try {
    const headers = {};
    const apiCall = await axios.post(
      url,
      customerInfoSubmit,
      headers,
      true
    );
    return apiCall.data;
  } catch (err) {
    throw err;
  }
};
