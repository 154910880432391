/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby-link';
import clsx from 'clsx';
import { myStyles } from '../signuppopup/styles';
import '../../styles.scss';
import Map from '../static-map/map';
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import { routerHandling } from './form-validation';

export default function LocationMap({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [helper, setHelper] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [lead, setLead] = useState({});

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
    setNextDisabled(!getLead?.buyingLocation?.selectedOption);
  }, []);

  const setPlace = (location) => {
    if (typeof location === 'object' && Object.keys(location).length > 0) {
      dispatch({
        type: 'SET_LOCATION',
        value: {
          ...lead,
          buyingLocation: {
            ...location
          }
        }
      });
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  };

  const handlerClick = (path) => {
    navigate(path);
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  return (
    <>
      {helper && (
      <>
        <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
      </>
      )}
      {!helper
    && (
    <>
      <div id="location-map" className={classes.root}>
        <div className={classes.prev}>
          <Link to={routerHandling(context, 'prev')}>
            <div className={classes.backPage}>
              <ArrowBackIcon />
              Previous
            </div>
          </Link>
          <div className={classes.backPage}>
            {data && data.showHelperText && (
              <Typography className={classes.helperButton} variant="caption">
                <div>
                  <Button onClick={helperShow} className={classes.haveQuestions}>
                    <ContactSupportIcon />
                    Have Questions
                  </Button>
                </div>
              </Typography>
            )}
          </div>
          {/* <IconButton aria-label="close" className={classes.margin} onClick={() => navigate('/')}>
            <CloseIcon />
          </IconButton> */}
        </div>
        <Grid
          spacing={3}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={12}>
            <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
          </Grid>
          <Grid item lg={12}>
            <div className="main-wrapper">
              {lead && <Map place={setPlace} lead={lead} required />}
            </div>
          </Grid>
          <Button id="location-next-btn" disabled={nextDisabled} onClick={() => handlerClick(routerHandling(context, 'next'))} size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained">Next</Button>
        </Grid>
      </div>

    </>
    )}
    </>
  );
}
