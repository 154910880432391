/* eslint-disable object-curly-newline */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/named */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { navigate } from 'gatsby-link';
import CountUp from 'react-countup';
import IconButton from '@material-ui/core/IconButton';
import { myStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import { AstContext, AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import RewardDisclaimer from './reward-disclaimer';
import ThemeContext from '../../utils/theme-context';
import { routerHandling } from './form-validation';

export default function PriveValue({ data, context }) {
  const classes = myStyles();
  const [currency] = React.useState('EUR');
  const astContext = useContext(AstContext);
  const dispatch = useContext(AstContextDispatch);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [cashbakCounter, setCashbakCounter] = useState(true);
  const [cashbackVisible, setCashbackVisible] = useState(false);
  const [helper, setHelper] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [cashback, setCashback] = useState(3500);
  const [minIndex, setMinIndex] = useState(0);
  const [nextDisable, setNextDisable] = useState(true);
  const [rewardTable] = useState(context.rewardTable.childrenContentfulRewardTableRewardTiersJsonNode);
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [lead, setLead] = useState({});
  const [LocState, setLocState] = useState(true);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
    if (getLead) {
      if (getLead.buyState) {
        const location = getLead.buyState;
        if (location === 'OK' || location === 'AK') {
          setLocState(false);
        }
      }

      if (getLead.buyMinPrice && getLead.buyMaxPrice) {
        setMinValue(getLead.buyMinPrice);
        setMaxValue(getLead.buyMaxPrice);
        setCashbackVisible(true);
        // setNextDisable(false);
      }
    }
  }, []);

  const updateAriaValues = () => {
    const ariaObj = document.getElementById('buy-outlined-select-currency');
    if (ariaObj) {
      ariaObj.setAttribute('aria-live', 'polite');
      ariaObj.setAttribute('aria-atomic', 'true');
    }
  };

  useEffect(() => {
    rewardTable.slice(minIndex, rewardTable.length).map((point) => {
      if (minValue && maxValue == point.max) {
        setCashback(point.reward);
        setCashbackVisible(true);
        const timerValue = setTimeout(() => {
          setNextDisable(false);
        }, 2500);
        setTimer(timerValue);
      }
    });
  }, [minIndex, minValue, maxValue, helper, disclaimer]);

  const changeCashback = (value) => {
    if (maxValue !== 0 && minValue !== 0) {
      for (let i = 0; i < rewardTable.length; i++) {
        if (minValue == rewardTable[i].min) {
          setMinIndex(i);
          break;
        }
        updateAriaValues();
      }
    }
  };

  const handlerClick = (path) => {
    const isOffRamp = lead?.isOffRamp || context.rewardTable?.childrenContentfulRewardTableRewardTiersJsonNode[0]?.max === parseInt(maxValue) && context.rewardTable?.childrenContentfulRewardTableRewardTiersJsonNode[0]?.min === parseInt(minValue);
    dispatch({ type: 'SET_PRICE_RANGE', value: { ...lead, buyMaxPrice: maxValue, buyMinPrice: minValue, buyreward: cashback, isOffRamp } });
    // if (lead?.buyingLocation?.selectedOption === "Key West, FL, USA" || ["02720"].includes(lead?.buyingLocation?.zip)) {
    //   navigate('/signup/buy/basic-realestateagent')
    // }
    navigate(path);
  };

  const helperShow = () => {
    setHelper(true);
    setCashbakCounter(false);
    setNextDisable(true);
  };

  const helperHide = () => {
    clearTimeout(timer);
    setHelper(false);
  };

  const showDisclaimer = () => {
    setDisclaimer(true);
    setCashbakCounter(false);
    setNextDisable(true);
  };

  const hideDisclaimer = () => {
    clearTimeout(timer);
    setDisclaimer(false);
  };

  const updateMinValue = (value) => {
    clearTimeout(timer);
    setMinValue(value);
    setMaxValue(0);
    setCashbackVisible(false);
    setNextDisable(true);
  };

  const updateMaxValue = (value) => {
    clearTimeout(timer);
    setMaxValue(value);
    changeCashback(value);
    setCashbakCounter(true);
    setNextDisable(true);
  };

  const closeModal = () => {
    localStorage.removeItem('lead');
    navigate('/');
  };
  const prevNavigation=() =>{
    const prevBtnUrl = routerHandling(context, "prev");
    navigate(`${prevBtnUrl}`)
  };
  return (
    <Container id="buy-price-range" className={classes.root}>
      <Grid container>
        {(helper && !disclaimer) && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {(!helper && disclaimer) && (
          <>
            <RewardDisclaimer disclaimerData={context.rewardDisclaimerText} disclaimerSwitch={hideDisclaimer} title="Reward Disclaimer" />
          </>
        )}
        {(!helper && !disclaimer)
          && (
            <>
              <div className={classes.root}>
                <div className={classes.prev}>
                <IconButton aria-label="back" onClick={prevNavigation} className={classes.backPage}>
                <ArrowBackIcon />
              </IconButton>
                  {data && data.showHelperText && (
                    <Typography variant="caption">
                      <div>
                        <Button onClick={helperShow} className={classes.haveQuestions}>
                          <ContactSupportIcon />
                          Have Questions
                        </Button>
                      </div>
                    </Typography>
                  )}
                  {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                    <CloseIcon />
                  </IconButton> */}
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                    {/* {data.showHelperText ? <Typography style={{ textAlign: 'center' }}>{data.helperText.internal.content}</Typography> : ''} */}
                  </Paper>
                </Grid>
                <Grid container spacing={3} className={classes.amount}>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <TextField
                      id="outlined-select-currency-native-minimum"
                      select
                      className={classes.inputField}
                      label="Minimum"
                      value={minValue}
                      onChange={(event) => updateMinValue(event.target.value)}
                      SelectProps={{
                        native: true
                      }}
                      variant="outlined"
                    >
                      {minValue === 0 && <option>Minimum</option>}
                      {context.rewardTable.childrenContentfulRewardTableRewardTiersJsonNode.map((option) => (
                        <option key={option.min} value={option.min}>
                          $
                          {option.min}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <TextField
                      id="outlined-select-currency-native-maximum"
                      select
                      className={classes.inputField}
                      label="Maximum"
                      value={maxValue}
                      onChange={(event) => updateMaxValue(event.target.value)}
                      SelectProps={{
                        native: true,
                        'aria-describedby': 'buy-outlined-select-currency'
                      }}
                      variant="outlined"
                    >
                      {maxValue === 0 && <option>Maximum</option>}
                      {context.rewardTable.childrenContentfulRewardTableRewardTiersJsonNode.map((option) => option.max
                        > minValue && (
                          <option key={option.max} value={option.max}>
                            $
                            {option.max}
                          </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {cashbackVisible
                  && (LocState ? (
                    <div>
                      <Box id="buy-outlined-select-currency" style={{ display: 'none' }}>
                        {data.bodyHelperText}
                        $
                        {cashback}
                      </Box>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper className={classes.paper} aria-describedby="buy-outlined-select-currency">
                          <Typography style={{ textAlign: 'center' }}>
                            {data.bodyHelperText}
                          </Typography>
                          <Typography variant="h2" style={{ textAlign: 'center', paddingBottom: '0' }}>
                            <sup>$</sup>
                            <CountUp separator="," className={classes.counter} end={cashback} duration={2} />
                          </Typography>
                        </Paper>
                      </Grid>
                    </div>
                  ) : '')}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={clsx(classes.paper, classes.typoAlign)} style={{ padding: '0px' }}>
                    <Button id="buy-price-range-next-btn" disabled={nextDisable} onClick={() => handlerClick(routerHandling(context, 'next'))} variant="contained" size="large" className={clsx(!nextDisable ? globalThemeCss.containedBtn : '', classes.nextButton)}>
                      Next
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div>
                    <Paper className={clsx(classes.paper, classes.typoAlign)}>
                      <Button id="buy-price-range-disclaimer" className={classes.haveQuestions} onClick={showDisclaimer}>Reward Disclaimer</Button>
                    </Paper>
                  </div>
                </Grid>
              </div>
            </>
          )}
      </Grid>
    </Container>
  );
}
