/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
/* eslint-disable import/prefer-default-export */
import { useState } from 'react';

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phonetype: '',
  formSubmitted: false,
  success: false
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ('firstName' in fieldValues) {
      temp.firstName = fieldValues.firstName ? '' : 'Please enter a valid First Name';
      if (fieldValues.firstName) {
        temp.firstName = /^([a-zA-Z0-9'‘’—–-]+\s?)*$/.test(fieldValues.firstName) ? '' : 'Please enter a valid First Name';
      }
    }

    if ('lastName' in fieldValues) {
      temp.lastName = fieldValues.lastName ? '' : 'Please enter a valid Last Name';
      if (fieldValues.lastName) {
        temp.lastName = /^([a-zA-Z0-9'‘’—–-]+\s?)*$/.test(fieldValues.lastName) ? '' : 'Please enter a valid Last Name';
      }
    }

    if ('email' in fieldValues) {
      temp.email = fieldValues.email ? '' : 'Please enter a valid Email';
      if (fieldValues.email) {
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? '' : 'Please enter a valid Email';
      }
    }

    if ('phoneNumber' in fieldValues) {
      temp.phoneNumber = fieldValues.phoneNumber ? '' : 'Please enter a valid Phone Number';
      if (fieldValues.phoneNumber) {
        temp.phoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(fieldValues.phoneNumber) ? '' : 'Please enter a valid Phone Number';
      }
    }

    if ('phonetype' in fieldValues) {
      temp.phonetype = fieldValues.phonetype ? '' : 'Please choose either Mobile or Home';
    }

    // if ('memberNumber' in fieldValues) {
    //   temp.memberNumber = fieldValues.memberNumber;
    //   if (fieldValues.memberNumber) {
    //     temp.memberNumber = /^[1-9]{1}\d{9}$/.test(fieldValues.memberNumber) ? '' : 'Please enter a valid Share Savings Account Number';
    //   }
    // }

    setErrors({
      ...temp
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = fieldValues.firstName
      && fieldValues.email
      && fieldValues.lastName
      && fieldValues.phoneNumber
      && fieldValues.phonetype
      && Object.values(errors).every((x) => x === '');
    return isValid;
  };
  return {
    handleInputValue,
    formIsValid,
    errors,
    validate
  };
};

export const urlValidation = (screentype, forUrl) => {
  try {
    const screentypeValue = screentype.toLowerCase().replace(/\s/g, '');
    if (screentypeValue.toUpperCase().includes('AGENTLIST')) {
      return 'agentlist';
    } else {
      return forUrl ? screentypeValue : screentype;
    }
  } catch (e) {
    return screentype;
  }
};

export const routerHandling = (context, type, thankYou) => {
  try {
    if (thankYou) {
      return '/ast-thank-you';
    }
    let returnValue = '/ast';
    if (context.type) {
      returnValue += `/${context.type.toLowerCase().replace(/\s/g, '')}`;
    }
    if (type === 'next' && context.next) {
      returnValue += `/${urlValidation(context.next.screenType, true)}`;
    } else if (type === 'prev' && context.previous) {
      if (context.pageIndex === 1) {
        return '/ast';
      }
      if (urlValidation(context.previous.screenType) === 'basic-contactinfo') {
        throw 'ee';
      }
      returnValue += `/${urlValidation(context.previous.screenType, true)}`;
    }
    return returnValue;
  } catch (e) {
    return '/ast';
  }
};

export const getLocalData = (name) => localStorage.getItem(name);
