/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-spacing */
/* eslint-disable arrow-parens */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useContext, useRef  } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Container,
  OutlinedInput,
  TextareaAutosize,
  TextField,
  Typography,
  Box
} from '@material-ui/core';
import { Link as LinkButton } from '@material-ui/core';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import { Button } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CardConfirmationView from './cardConfirmationView';
import { myStyles } from '../signuppopup/styles';
import ThemeContext from '../../utils/theme-context';
import { themeStyles } from '../../styles';
import RewardDisclaimer from './reward-disclaimer';
import { AstContextDispatch } from '../../utils/ast-context';
import { routerHandling } from './form-validation';
import SEO from '../seo-component/seo';

export default function AgentConfirmation({ brokerDetails, changeSelection, context, selectAgent }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [buyer, setBuyer] = useState([]);
  const [seller, setSeller] = useState([]);
  const [buyerSeller, setBuyerSeller] = useState([]);
  const [gridView, setGridView] = useState(5);
  const [lead, setLead] = useState({});
  const [checkBox, setcheckBox] = React.useState(false);
  const { ESIGN, ABAD } = checkBox;
  const [open, setOpen] = React.useState(false);
  const CHARACTER_LIMIT = '255';
  const dispatch = useContext(AstContextDispatch);
  const inputElement = useRef("");
  const [sellerNote, setSellerNote] = useState({
    view: false,
    notes: ''
  });
  const [buyerNote, setBuyerNote] = useState({
    view: false,
    notes: ''
  });
  const [buySellNote, setBuySellNote] = useState({
    view: false,
    notes: ''
  });

  const fetchLeadValue = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    fetchLeadValue();
    const getLead = JSON.parse(localStorage.getItem('lead'));
    if (getLead?.sellingComments) {
      setSellerNote({
        ...sellerNote,
        notes: getLead?.sellingComments
      });
    }
    if (getLead?.buyingComments) {
      setBuyerNote({
        ...buyerNote,
        notes: getLead?.buyingComments
      });
    }
    if (getLead?.buysellComments) {
      setBuySellNote({
        ...buySellNote,
        notes: getLead?.buysellComments
      });
    }
  }, []);

  useEffect(() => {
    let buy = [];
    let sell = [];
    let buySell = [];
    brokerDetails.map((broker, index) => {
      switch (broker.type) {
        case 'buy':
          buy.push(broker);
          break;
        case 'sell':
          sell.push(broker);
          break;
        default:
          buy.push(broker);
          sell.push(broker);
      }
    });
    setBuyer(buy);
    setSeller(sell);
    // setBuyerSeller(buySell);
  }, [brokerDetails]);

  const updateSellerNote = (viewValue) => {
    setSellerNote({
      ...sellerNote,
      view: viewValue
    });
  };

  const updateBuyerNote = (viewValue) => {
    setBuyerNote({
      ...buyerNote,
      view: viewValue
    });
  };

  const updateBuyerSellerNote = (viewValue) => {
    setBuySellNote({
      ...buySellNote,
      view: viewValue
    });
  };

  const handleChangeEsign = (event) => {
    setcheckBox({ ...checkBox, [event.target.name]: event.target.checked });
    const getLead = JSON.parse(localStorage.getItem('lead'));
    dispatch({
      type: 'SET_ESIGN',
      value: {
        ...getLead, isEsign: event.target.checked
      }
    });
  };

  const handleBuySellText = name => event => {
    setBuySellNote({ ...buySellNote, [name]: event.target.value });
    const getLead = JSON.parse(localStorage.getItem('lead'));
    dispatch({
      type: 'SET_BUYING_SELLING_AGENT_COMMENT',
      value: {
        ...getLead, buysellComments: event.target.value
      }
    });
  };

  const handleChangeAbad = (event) => {
    setcheckBox({ ...checkBox, [event.target.name]: event.target.checked });
    dispatch({
      type: 'SET_ABAD',
      value: {
        ...lead, isABAD: event.target.checked
      }
    });
  };

  const handleBuyerText = name => event => {
    setBuyerNote({ ...buyerNote, [name]: event.target.value });
    const getLead = JSON.parse(localStorage.getItem('lead'));

    dispatch({
      type: 'SET_BUYING_AGENT_COMMENT',
      value: {
        ...getLead, buyingComments: event.target.value
      }
    });
  };

  const handleSellerText = name => event => {
    setSellerNote({ ...sellerNote, [name]: event.target.value });
    dispatch({
      type: 'SET_SELLING_AGENT_COMMENT',
      value: {
        ...lead, sellingComments: event.target.value
      }
    });
  };

  const error = [ESIGN, ABAD].filter((v) => v).length !== 2;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseWithoutAccept = () => {
    dispatch({
      type: 'SET_OFF_RAMP_FLAG',
      value: {
        ...lead,
        broker: [...brokerDetails],
        sellingComments: sellerNote.notes,
        buyingComments: buyerNote.notes,
        isOffRamp: true,
        offrampReason: 'PROCEED_WITHOUT_AGREEMENTS',
        helpScreenCode: 18,
        signatures: [{
          isChecked: false,
          tstamp: new Date(),
          type: 'abad'
        },
        {
          isChecked: false,
          tstamp: new Date(),
          type: 'esign'
        }]
      }
    });
    navigate(routerHandling({}, 'next', true));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFinish = () => {
    dispatch({
      type: 'SET_SELECTED_AGENT',
      value: {
        ...lead,
        broker: [...brokerDetails],
        sellingComments: sellerNote.notes,
        buyingComments: buyerNote.notes,
        signatures: [{
          isChecked: true,
          tstamp: new Date(),
          type: 'abad'
        },
        {
          isChecked: true,
          tstamp: new Date(),
          type: 'esign'
        }]
      }
    });
    fetchLeadValue();
    navigate(routerHandling({}, 'next', true));
  };
  const [esign, setESIGN] = useState(false);
  const [abad, setABAD] = useState(false);
  const showESIGN = () => {
    setESIGN(true);
  };
  const showABAD = () => {
    setABAD(true);
  };
  const hideESIGN = () => {
    dispatch({
      type: 'SET_ESIGN',
      value: {
        ...lead, isEsign: false
      }
    });
    setESIGN(false);
  };
  const hideABAD = () => {
    dispatch({
      type: 'SET_ABAD',
      value: {
        ...lead, isABAD: false
      }
    });
    setABAD(false);
  };

  const closeModal = () => {
    navigate('/');
    localStorage.removeItem('lead');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
  }; 
  useEffect(()=>{
    if(inputElement) {
      inputElement.current.focus()
    };
    
  },[esign,abad])
  return (
    <Container ref={inputElement} tabIndex={-1} id="agent-confirmation" classes={{ root: clsx(classes.agentConfirmationRoot, classes.root) }}>
     
      <Grid container className={classes.centerScreen} style={{ padding: '0em 1em' }}>
        {(esign && !abad) && (
          <>
            <RewardDisclaimer disclaimerData={context.legalAgreementsText[0].disclosureText} disclaimerSwitch={hideESIGN} title="Electronic Records and Signature Disclosure" />
          </>
        )}
        {(!esign && abad) && (
          <>
            <RewardDisclaimer disclaimerData={context.legalAgreementsText[1].disclosureText} disclaimerSwitch={hideABAD} title="Affiliated Business Arrangement Disclosure" />
          </>
        )}
        {(!esign && !abad) && (
          <>
            <SEO title="Review and Confirm Agents" />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={clsx(classes.paper, classes.typoAlign)}>
                <Typography variant="h2" component="h2" className={clsx(classes.noMarginPadding, classes.pagestitle)}>One Last Step</Typography>
                <Typography variant="h5">Review and confirm your selection below</Typography>
                <Button id="change-your-selection" variant="text" onClick={changeSelection}>
                  <Typography variant="overline" className={classes.changeSelection}>
                    <span className={classes.selectionUndo}>
                      <ReplaySharpIcon transform="rotate(317)" />
                    </span>
                    <span className={classes.selectionText}>Change your selection</span>
                  </Typography>
                </Button>
              </Paper>
            </Grid>
            {seller.length > 0 && (
              <>
                <Grid item xs={gridView} sm={gridView} md={gridView} lg={gridView} className={lead && lead.operationType === 'Both' ? classes.agentGridBoth : classes.agentGrid}>
                  <div className={classes.agentTitle}>
                    <Typography variant="h3" className={classes.agentNoteTitle}>Selling Agent(s):</Typography>
                    {sellerNote.view ? (
                      <Button variant="text" size="small" id="sell-agent-save-comment" aria-controls="sell-comments" aria-expanded="true" onClick={e => { updateSellerNote(false); }}>
                        <Typography>
                          <SaveIcon fontSize="small" className={classes.agentIcon} />
                          <span className={classes.textUnderline}>Save Notes</span>
                        </Typography>
                      </Button>
                    ) : (
                      <>
                        {sellerNote.notes.length > 0 ? (
                          <Button size="small" variant="text" id="sell-agent-edit-comment" aria-controls="sell-comments" aria-expanded="false" onClick={e => { updateSellerNote(true); }}>
                            <Typography>
                              <EditIcon fontSize="small" className={classes.agentIcon} />
                              <span className={classes.textUnderline}>Edit Notes</span>
                            </Typography>
                          </Button>
                        ) : (
                          <Button size="small" variant="text" id="sell-agent-add-comment" aria-controls="sell-comments" aria-expanded="false" onClick={e => { updateSellerNote(true); }}>
                            <Typography>
                              <span className={classes.agentIcon}><AddIcon fontSize="small" /></span>
                              <span className={classes.textUnderline}>Add Notes</span>
                            </Typography>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <hr className={classes.hrLine} />
                  </div>
                  <div id="seller-field" className={clsx(classes.contentView, sellerNote.view ? '' : classes.contentAgent)}>
                    {sellerNote.view ? (
                      <div id="sell-comments" className={classes.agentTextContent}>
                        <label className={classes.labelText} for="sell-agent-comment">Agents will use this note to help better understand your needs:</label>
                        <TextField
                          multiline
                          role="alert"
                          rows={13}
                          variant="outlined"
                          value={sellerNote.notes}
                          onChange={handleSellerText('notes')}
                          inputProps={{
                            maxlength: CHARACTER_LIMIT
                          }}
                          FormHelperTextProps={{ className: classes.textcount }}
                          helperText={`${sellerNote.notes.length} / ${CHARACTER_LIMIT}`}
                          id="sell-agent-comment"
                        />
                      </div>
                    ) : (
                      seller.map((sell, index) => (
                        <CardConfirmationView details={sell} selectProfile={selectAgent} lead={lead} />
                      ))
                    )}
                  </div>
                </Grid>
              </>
            )}
            {buyer.length > 0 && (
              <>
                <Grid item xs={gridView} sm={gridView} md={gridView} lg={gridView} className={lead && lead.operationType === 'Both' ? classes.agentGridBoth : classes.agentGrid}>
                  <div className={classes.agentTitle}>
                    <Typography variant="h3" className={classes.agentNoteTitle}>Buying Agent(s):</Typography>
                    {buyerNote.view ? (
                      <Button variant="text" size="small" id="buy-agent-save-comment" aria-controls="buy-comments" aria-expanded="true" onClick={e => { updateBuyerNote(false); }}>
                        <Typography>
                          <SaveIcon fontSize="small" className={classes.agentIcon} />
                          <span className={classes.textUnderline}>Save Notes</span>
                        </Typography>
                      </Button>
                    ) : (
                      <>
                        {buyerNote.notes.length > 0 ? (
                          <Button size="small" variant="text" id="buy-agent-edit-comment" aria-controls="buy-comments" aria-expanded="false" onClick={e => { updateBuyerNote(true); }}>
                            <Typography>
                              <EditIcon fontSize="small" className={classes.agentIcon} />
                              <span className={classes.textUnderline}>Edit Notes</span>
                            </Typography>
                          </Button>
                        ) : (
                          <Button variant="text" size="small" id="buy-agent-add-comment" aria-controls="buy-comments" aria-expanded="false" onClick={e => { updateBuyerNote(true); }}>
                            <Typography>
                              <AddIcon fontSize="small" className={classes.agentIcon} />
                              <span className={classes.textUnderline}>Add Notes</span>
                            </Typography>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <hr className={classes.hrLine} />
                  </div>
                  <div id="buyer-field" className={clsx(classes.contentView, buyerNote.view ? '' : classes.contentAgent)}>
                    {buyerNote.view ? (
                      <div id="buy-comments" className={classes.agentTextContent}>
                        <label className={classes.labelText} for="buy-agent-comment">Agents will use this note to help better understand your needs:</label>
                        <TextField
                          multiline
                          role="alert"
                          rows={13}
                          variant="outlined"
                          value={buyerNote.notes}
                          onChange={handleBuyerText('notes')}
                          inputProps={{
                            maxlength: CHARACTER_LIMIT
                          }}
                          FormHelperTextProps={{ className: classes.textcount }}
                          helperText={`${buyerNote.notes.length} / ${CHARACTER_LIMIT}`}
                          id="buy-agent-comment"
                        />
                      </div>
                    ) : (
                      buyer.map((buy, index) => (
                        <CardConfirmationView details={buy} selectProfile={selectAgent} lead={lead} />
                      ))
                    )}
                  </div>
                </Grid>
              </>
            )}
            {/* {buyerSeller.length > 0 && (
              <>
                <Grid item xs={gridView} sm={gridView} md={gridView} lg={gridView} className={lead && lead.operationType === 'Both' ? classes.agentGridBoth : classes.agentGrid}>
                  <div className={classes.agentTitle}>
                    <Typography variant="h3" className={classes.agentNoteTitle}>BuySell Agent(s)</Typography>
                    {buySellNote.view ? (
                      <Typography id="both-agent-save-comment" style={{ cursor: 'pointer' }} onClick={e => { updateBuyerSellerNote(false); }}>
                        <span className={classes.agentIcon}><SaveIcon fontSize="small" /></span>
                        <span className={classes.textUnderline}>Save Notes</span>
                      </Typography>
                    ) : (
                      <>
                        {buySellNote.notes.length > 0 ? (
                          <Typography id="both-agent-edit-comment" style={{ cursor: 'pointer' }} onClick={e => { updateBuyerSellerNote(true); }}>
                            <span className={classes.agentIcon}><EditIcon fontSize="small" /></span>
                            <span className={classes.textUnderline}>Edit Notes</span>
                          </Typography>
                        ) : (
                          <Typography id="both-agent-add-comment" style={{ cursor: 'pointer' }} onClick={e => { updateBuyerSellerNote(true); }}>
                            <span className={classes.agentIcon}><AddIcon fontSize="small" /></span>
                            <span className={classes.textUnderline}>Add Notes</span>
                          </Typography>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <hr className={classes.hrLine} />
                  </div>
                  <div className={clsx(classes.contentView, sellerNote.view ? '' : classes.contentAgent)}>
                    {buySellNote.view ? (
                      <div className={classes.agentTextContent}>
                        <Typography>Agents will use this note to help better understand your needs:</Typography>
                        <TextField
                          multiline
                          rows={13}
                          variant="outlined"
                          value={buySellNote.notes}
                          onChange={handleBuySellText('notes')}
                          inputProps={{
                            'aria-label': 'weight',
                            maxlength: CHARACTER_LIMIT
                          }}
                          labelWidth={0}
                          className={classes.agentTextBox}
                          FormHelperTextProps={{ className: classes.textcount }}
                          helperText={`${buySellNote.notes.length} / ${CHARACTER_LIMIT}`}
                        />
                      </div>
                    ) : (
                      buyerSeller.map((sell, index) => (
                        <CardConfirmationView details={sell} selectProfile={selectAgent} />
                      ))
                    )}
                  </div>
                </Grid>
              </>
            )} */}
            <Grid item xs={12} sm={12} md={12} lg={12} classes={{ root: classes.gridFooter }}>
              <FormControl required error={error} className={classes.formControl}>
              <FormGroup role="group" aria-labelledby='checkbox-desc'>
                  <FormControlLabel
                    classes={{ label: classes.checkBoxMargin }}
                    control={<Checkbox className={classes.themeSecondaryColor} checked={ESIGN} id="esign-checkbox" onChange={handleChangeEsign} name="ESIGN" />}
                    label={<label htmlFor="esign-checkbox"><Typography className={classes.checkBoxMargin}> I accept the <LinkButton component="button" id="esign-link" onClick={showESIGN} className={classes.textLegal}> Electronic Records and signature Disclosure and Consent</LinkButton></Typography></label>}
                  />
                  <FormControlLabel
                    classes={{ label: classes.checkBoxMargin }}
                    control={<Checkbox className={classes.themeSecondaryColor} checked={ABAD} id="abad-checkbox" onChange={handleChangeAbad} name="ABAD" />}
                    label={<label htmlFor="abad-checkbox"><Typography className={classes.checkBoxMargin}> I accept the <LinkButton component="button" id="abad-link" onClick={showABAD} className={classes.textLegal}> Affiliated Business Arrangement Disclosure </LinkButton></Typography></label>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <div id="checkbox-desc" className={classes.sronly}>Select the check boxes below to confirm you have read and accept the disclosure documentation</div>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {error ? (
                <Paper className={clsx(classes.paper, classes.typoAlign)}>
                  <Button id="finish-without-btn" className={clsx(globalThemeCss.outlinedBtn, classes.nextButton)} variant="outlined" onClick={handleClickOpen} size="large"> Finish  </Button>
                </Paper>
              ) : (
                <Paper className={clsx(classes.paper, classes.typoAlign)}>
                  <Button id="finish-btn" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained" onClick={() => handleFinish()} size="large">
                    Finish
                  </Button>
                </Paper>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                // aria-labelledby="alert-dialog-title" 
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                role="dialog"
                aria-modal="true"
                aira-label="Finish without accepting"
              >
                <DialogTitle id="alert-dialog-title" className={classes.dialogBox}>
                  <Grid className={classes.dialogClose}>
                    <IconButton aria-label="close" onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h2" className={classes.dialogHeading}>Finish Without Accepting?</Typography>
                  </Grid>
                </DialogTitle>
                <DialogContent id="alert-dialog-description">
                  <Typography>
                    If you attempt to complete your request without accepting our agreements, you will lose your selected agent and be contacted by a real estate coordinator to help you find your agent.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Grid className={classes.alignButtons}>
                    <Paper className={clsx(classes.paper, classes.typoAlign)}>
                      <Button variant="text" id="finish-modal-cancel-btn" onClick={handleClose} className={clsx(globalThemeCss.textBtn, classes.nextButton)} size="large">
                        Cancel
                      </Button>
                      <Button variant="contained" id="finish-modal-without-btn" onClick={handleCloseWithoutAccept} className={clsx(globalThemeCss.containedBtn, classes.nextButton)} autoFocus size="large">
                        Finish without Accepting
                      </Button>
                    </Paper>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}
