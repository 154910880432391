/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
import ThemeContext from '../../utils/theme-context';
import { myStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import ContactForm from './contact-form';
import { routerHandling } from './form-validation';
import { AstContextDispatch } from '../../utils/ast-context';
import SEO from '../seo-component/seo';

export default function Helper({ helperData, helperSwitch, contextData, title }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [renderCustomerScreen, setRenderCustomerScreen] = useState(false);
  const dispatch = useContext(AstContextDispatch);
  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    if (getLead?.customerInfo && localStorage.getItem('client-details')) {
      localStorage.removeItem('client-details');
    }
    if (!localStorage.getItem('client-details')) {
      const jsonObject = {
        clientNumber: contextData ? contextData.metadata.clientNumber : null,
        clientName: contextData ? contextData.metadata.leadSource : null,
        partnerId: contextData ? contextData.appData.partnerId : null
      };
      localStorage.setItem('client-details', JSON.stringify(jsonObject));
    }
  }, []);

  const NextViewPage = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    // setLead(getLead || {});
    if (getLead?.customerInfo) {
      dispatch({ type: 'SET_OFF_RAMP_FLAG', value: { ...getLead, isOffRamp: true, offrampReason: 'REACH_A_REPRESENTATIVE', helpScreenCode: helperData.screenCode } });
      navigate(routerHandling({}, 'next', true));
    } else {
      setRenderCustomerScreen(true);
    }
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  //   localStorage.removeItem('rewards');
  //   localStorage.removeItem('mobile');
  //   localStorage.removeItem('client-details');
  //   localStorage.removeItem('mongoContactId');
  //   localStorage.removeItem('pageLength');
  // };

  return (

    <Container aria-label={title || 'Have Questions'} id="helper" className={classes.root} style={{ padding: '0' }}>
      <SEO title={title || 'Have Questions'} />
      {/* <div role="dialog" aria-modal="true" aria-label="Have Questions"> */}
      <Grid container>
        <div className={classes.buy}>
          <div className={classes.prev}>
            <div className={classes.backPage}>
              <IconButton aria-label="back" onClick={helperSwitch}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.backPage}> </div>
            {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
              <CloseIcon />
            </IconButton> */}
          </div>
          {!renderCustomerScreen ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" className={classes.internalContent}>{helperData.helperLabel}</Typography>
                  <Typography className={classes.internalSubText}>{helperData.helperText.internal.content}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} container direction="column" justifyContent="center" alignItems="center" className={classes.helperQuestion}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" className={classes.internalContent}>Still Have Questions?</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={clsx(classes.paper, classes.typoAlign)}>
                  <Button id="reach-a-representative" size="large" variant="contained" onClick={NextViewPage} className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                    Reach A Representative
                  </Button>
                </Paper>
              </Grid>
            </>
          ) : (
            <ContactForm />
          )}
        </div>
      </Grid>
      {/* </div> */}
    </Container>
  );
}
