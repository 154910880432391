/* eslint-disable prefer-const */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { Typography, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Badge from '@material-ui/core/Badge';
import { navigate } from 'gatsby-link';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CountUp from 'react-countup';
import { myStyles, useQontoStepIconStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import { AstContextDispatch } from '../../utils/ast-context';
import { routerHandling } from './form-validation';
import Helper from './helper';
import AgentProfile from './agent-profile';
import Loading from './loading';
import AgentConfirmation from './agent-confirmation';
import ThemeContext from '../../utils/theme-context';
import SEO from '../seo-component/seo';
import DefaultProfileImage from '../../images/default-agent-image.png';
import { useRef } from 'react';
import * as DOMPurify from 'dompurify';

const CryptoJS = require('crypto-js');

export default function AgentList({ data, context }) {
  const classes = myStyles();
  const [helper, setHelper] = useState(false);
  const pageRef = useRef(null);
  const [agentDetailsBuy, setAgentDetailsBuy] = useState([]);
  const [agentDetailsSell, setAgentDetailsSell] = useState([]);
  const [agentDetailsBoth, setAgentDetailsBoth] = useState([]);
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [brokerDetails, setBrokerDetails] = useState([]);
  const dispatch = useContext(AstContextDispatch);
  const [pageIndex, setPageIndex] = useState(0);
  const [lead, setLead] = useState({});
  const [selectedAgentOption, setSelectedAgentOption] = useState([]);
  const [lastStep, setLastStep] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setStep] = React.useState([]);
  const [selectedProfile, setSelectedProfile] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const [reward, setreward] = useState(0);
  const [headerText, setHeaderText] = useState({
    sell: '',
    buy: '',
    buySell: ''
  });
  const [currentHeader, setCurrentHeader] = useState('');
  let counter = 1;
  const emptyValue = ' ';

  

  useEffect(() => {
    if (pageRef) {
      pageRef.current.parentElement.parentElement.parentElement.focus();
    }
  });
  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);

    if (getLead) { if (getLead.reward) setreward(reward); }
  }, []);

  useEffect(() => {
    if (loading) {
      const fetchUrl = generateAgentDetails();
      let encryptedQueryParams = CryptoJS.AES.encrypt(JSON.stringify(fetchUrl), process.env.GATSBY_ENCRYPTED_QUERY_PARAMS_KEY).toString();
      callAgentApi(`queryParams=${encodeURIComponent(encryptedQueryParams)}`);
    }
  }, [loading]);

  const callAgentApi = async (paramsUrl) => {
    try {
      const generatedurl = context.envVars.AST_BROKER_WITH_AGENTS_URL ? `${context.envVars.AST_BROKER_WITH_AGENTS_URL}?${paramsUrl}` : `${process.env.GATSBY_AST_BROKER_WITH_AGENTS_ENDPOINT}?${paramsUrl}`;
      const config = {
        headers: {
          'x-trace-id': 0,
          'x-partner': 'militaryrewards',
          'x-processor': 'cartus'
        }
      };
      const fetchAgent = await axios.get(generatedurl, config);
      if (fetchAgent.data) {
        setLoading(false);
        if (!fetchAgent.data.status && fetchAgent.data.offramp_reason) {
          const newLead = JSON.parse(localStorage.getItem('lead'));
          dispatch({
            type: 'SET_OFF_RAMP_FLAG',
            value: { ...newLead, isOffRamp: true, offrampReason: fetchAgent.data.offramp_reason }
          });
          navigate(routerHandling({}, 'next', true));
        } else if (fetchAgent.data.brokers.length > 0 && fetchAgent.data.status) {
          setStep([...fetchAgent.data.brokers]);
          setBrokerDetails(fetchAgent.data.brokers);
          const sellerAgent = [];
          const buyerAgent = [];
          const bothAgent = [];
          const textHeader = {
            sell: '',
            buy: '',
            buySell: ''
          };
          fetchAgent.data.brokers.filter((agentDetails, index) => {
            if (agentDetails.provides_agents_for === 'sell') {
              sellerAgent.push(agentDetails);
              context.sellPages.filter((listSell) => {
                if (listSell.screenType === 'Sell - Agent List') {
                  textHeader.sell = listSell.question.question;
                  textHeader.buySell = listSell.questionForBothType.questionForBothType;
                }
              });
            } else if (agentDetails.provides_agents_for === 'buy') {
              buyerAgent.push(agentDetails);
              context.buyPages.filter((listBuy) => {
                if (listBuy.screenType === 'Buy - Agent List') {
                  textHeader.buy = listBuy.question.question;
                  textHeader.buySell = listBuy.questionForBothType.questionForBothType;
                }
              });
            } else if (agentDetails.provides_agents_for === 'buysell') {
              bothAgent.push(agentDetails);
              setHeaderText(data.questionForBothType.questionForBothType);
              context.bothPages.filter((listBuySell) => {
                if (listBuySell.screenType === 'Buy - Agent List') {
                  textHeader.buy = listBuySell.question.question;
                  textHeader.buySell = listBuySell.questionForBothType.questionForBothType;
                } else if (listBuySell.screenType === 'Sell - Agent List') {
                  textHeader.sell = listBuySell.question.question;
                  textHeader.buySell = listBuySell.questionForBothType.questionForBothType;
                }
              });
            }
          });
          setAgentDetailsSell([...sellerAgent]);
          setAgentDetailsBuy([...buyerAgent]);
          setAgentDetailsBoth([...bothAgent]);
          setHeaderText(textHeader);
        } else {
          dispatch({ type: 'SET_OFF_RAMP_FLAG', value: { ...lead, isOffRamp: true } });
          navigate(routerHandling({}, 'next', true));
        }
      } else {
        setLoading(false);
        const newLead = JSON.parse(localStorage.getItem('lead'));
        dispatch({
          type: 'SET_OFF_RAMP_FLAG',
          value: { ...newLead, isOffRamp: true, offrampReason: 'LEAD_ROUTER_UNAVAILABLE' }
        });
        navigate(routerHandling({}, 'next', true));
      }
    } catch (e) {
      console.log(e, 'the error');
      setLoading(false);
      navigate('/ast-error');
    }
  };

  function QontoStepIcon(props) {
    const classesStep = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div className={clsx(classesStep.root, { [classesStep.active]: active, [classesStep.completed]: completed })}>
        {completed ? <CheckCircleIcon aria-hidden={null} role="img" aria-label={props['aria-label']} className={classesStep.completed} /> : <div aria-hidden={null} role="img" aria-label={props['aria-label']} aria-current={props['aria-current']} className={classesStep.circle} />} </div>
    );
  }

  const generateAgentDetails = () => {
    const leadDetails = JSON.parse(localStorage.getItem('lead'));
    const clientDetails = JSON.parse(localStorage.getItem('client-details'));
    if (leadDetails) {
      let newString = '';
      const {
        sellingLocation, buyingLocation, buyMaxPrice, buyMinPrice, sellMaxPrice, sellMinPrice, buyPropTypeCode, customerInfo, sellPropTypeCode, sellPropPrice
      } = leadDetails;
      if ((leadDetails.operationType === 'Both' || leadDetails.operationType === 'Buying') && leadDetails?.buyingLocation?.state !== 'IA') {
        newString += `zipcodeBuy=${buyingLocation && buyingLocation.zip ? buyingLocation.zip : ''}&proptypeBuy=${buyPropTypeCode.toString()}&lowBuy=${buyMinPrice}&highBuy=${buyMaxPrice}&`;
      }
      if ((leadDetails.operationType === 'Both' || leadDetails.operationType === 'Selling') && leadDetails?.sellingLocation?.state !== 'IA') {
        newString += `zipcodeSell=${sellingLocation && sellingLocation.zip ? sellingLocation.zip : ''}&proptypeSell=${sellPropTypeCode.length > 0 ? sellPropTypeCode[0].toString() : buyPropTypeCode[0].toString()}&lowSell=${sellPropPrice || buyMinPrice}&highSell=${sellPropPrice || buyMaxPrice}&`;
      }
      switch (leadDetails.operationType) {
        case 'Both':
          newString += 'operationType=BUYSELL&';
          break;
        case 'Selling':
          newString += 'operationType=SELL&';
          break;
        case 'Buying':
          newString += 'operationType=BUY&';
          break;
      }
      if (customerInfo) {
        newString += `uLname=${customerInfo.lastName}&uEmail=${customerInfo.email}&uMobilePhone=${customerInfo.phonetype !== 'Home'}&uPhone=${customerInfo.phoneNumber}&uMeta=${customerInfo.memberNumber ? customerInfo.memberNumber : ''}&clientNumber=${clientDetails.clientNumber}`;
      }
      return newString;
    }
  };

  const chooseForMe = () => {
    const agentSelectedLocal = [];
    brokerDetails.map((broker) => {
      if (broker.agents && broker.agents.length > 0) {
        const agent = broker.agents[0];
        agent.type = broker.provides_agents_for;
        agent.brand_id = broker.brand_id;
        agent.company_id = broker.company_id;
        agent.broker_oid = broker.broker_oid;
        agent.wasAutoSelected = true;
        agentSelectedLocal.push(agent);
      }
    });
    setLastStep(true);
    setPageIndex(2);
    setSelectedAgentOption([...agentSelectedLocal]);
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  useEffect(() => {
    updateCurrentHeader();
  }, [selectedAgentOption, headerText]);

  const updateCurrentHeader = () => {
    let currentText = '';
    if ((lead.operationType === 'Both' || lead.operationType === 'Selling') && (agentDetailsSell.length > 0 && selectedAgentOption.length < 2 && agentDetailsSell.length > selectedAgentOption.length)) {
      currentText = headerText.sell;
    } else if (((lead.operationType === 'Both' && selectedAgentOption.length === 2) || (lead.operationType === 'Buying') || (agentDetailsSell.length === selectedAgentOption.length) && (agentDetailsBuy.length > 0))) {
      currentText = headerText.buy;
    } else {
      currentText = headerText.buySell;
    }
    setCurrentHeader(currentText);
  };

  const getImageFromBrandName = (id, alt) => {
    const logo = context.brandLogo.find((val) => {
      const logoID = val.identifier.split('-')[0].toUpperCase();
      return logoID === id;
    });
    return logo ? <div className={classes.brandImageDiv}><img className={classes.brandImage} style={{ maxHeight: '100%', verticalAlign: 'middle', maxWidth: '100%', objectFit: 'scale-down', aspectRatio: 'auto' }} src={logo.logo.file.url} alt={`${alt} logo`} /></div> : '';
  };

  const updateAgentValue = (agentDetails) => {
    const newValue = selectedAgentOption;
    newValue.push(agentDetails);
    setSelectedAgentOption([...newValue]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoader(true);
    switch (lead.operationType) {
      case 'Selling':
        if (newValue.length === 2 || (steps.length === 1 && newValue.length === 1)) {
          setLastStep(true);
        }
        break;
      case 'Buying':
        if (newValue.length === 1) {
          setLastStep(true);
        }
        break;
      case 'Both':
        if (newValue.length === 3 || (agentDetailsBoth.length === newValue.length) || (newValue.length === (agentDetailsBuy.length + agentDetailsSell.length))) {
          setLastStep(true);
        }
        break;
    }
  };
  const closeModal = () => {
    navigate('/');
    localStorage.removeItem('lead');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
  };

  const clearSelectedAgentOption = () => {
    if (lastStep) {
      setSelectedProfile(null);
    } else {
      setLastStep(false);
      setSelectedProfile(null);
    }
  };

  const clearSelectedRecords = () => {
    dispatch({
      type: 'CLEAR_AGENT_COMMENT',
      value: {
        ...lead, buyingComments: '', sellingComments: ''
      }
    });
    setSelectedAgentOption([]);
    setLastStep(false);
    setActiveStep(0);
    setSelectedProfile(null);
  };

  const updatedBasedOnAgentProfile = (agent) => {
    if (lastStep) {
      setSelectedProfile(null);
    } else {
      setSelectedProfile(null);
      updateAgentValue(agent);
    }
  };

  const handleShowmeAgent = () => {
    setPageIndex(1);
  };



  return (
    <Container ref={pageRef} id="agent-list" className={classes.root}>
      <Grid container className={classes.wrapperAgent}>
        {pageIndex === 0 ? (
          <>
            {loading
              && <Loading description="Finding Agents" />}
            {helper && (
              <>
                <Helper helperData={data} contextData={context} helperSwitch={helperHide} title="Why Multiple Agents" />
              </>
            )}
            {!helper && (
              <>
                {!loading && (
                  <div>
                    <SEO title="Available Brokers" />
                    {brokerDetails.length > 0
                    && (
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '4em', padding: '0em 1em' }}>
                        <Typography variant="h2" className={classes.internalContent}>
                          {`You'll be choosing ${brokerDetails.length} agent(s) from the following brokerage(s)`}
                        </Typography>
                        {data && data.showHelperText && (brokerDetails.length > 1) && (lead.operationType === 'Both' || lead.operationType === 'Selling') && (
                          <div>
                            <Typography className={classes.internalContent}>
                              <Button id="multiple-agents" className={classes.haveQuestions} onClick={helperShow}>
                                {data.helperLabel}
                              </Button>
                            </Typography>
                          </div>
                        )}
                        <Grid className={classes.brandLogo}>
                          {brokerDetails.map((value) => (
                            <Grid key={value.broker_oid} style={{ margin: '1% 3%', maxWidth: '30%' }}>
                              {getImageFromBrandName(value.brand_id, value.brand_name)}
                            </Grid>
                          ))}
                        </Grid>
                        <Grid className={classes.helperField} style={{ textAlign: 'center' }}>
                          <hr className={classes.hrDivider50} />
                          <Grid style={{ paddingTop: '10px' }}>
                            <Typography>Your reward may be up to</Typography>
                            {lead.operationType === 'Buying' || lead?.sellingLocation?.state === 'IA' ? (
                              <Typography variant="h2">
                                <sup>$</sup>
                                <CountUp className={classes.counter} separator="," end={lead.buyreward} duration={2} />
                              </Typography>
                            ) : (
                              ''
                            )}
                            {lead.operationType === 'Selling' || lead?.buyingLocation?.state === 'IA' ? (
                              <Typography variant="h2">
                                <sup>$</sup>
                                <CountUp className={classes.counter} separator="," end={lead.sellreward} duration={2} />
                              </Typography>
                            ) : (
                              ''
                            )}
                            {lead.operationType === 'Both' && lead?.buyingLocation?.state !== 'IA' && lead?.sellingLocation?.state !== 'IA' ? (
                              <Grid>
                                <Grid style={{ display: 'inline-block', padding: '15px' }}>
                                  Selling
                                  <Grid>
                                    <Typography variant="h4" style={{ textAlign: 'center', paddingBottom: '0' }}>
                                      <sup>$</sup>
                                      <CountUp separator="," end={lead.sellreward} duration={2} style={{ fontWeight: 'bold' }} />
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid style={{ display: 'inline-block', verticalAlign: 'super' }}> + </Grid>
                                <Grid style={{ display: 'inline-block', padding: '15px' }}>
                                  Buying
                                  <Grid>
                                    <Typography variant="h4" style={{ textAlign: 'center', paddingBottom: '0' }}>
                                      <sup>$</sup>
                                      <CountUp separator="," end={lead.buyreward} duration={2} style={{ fontWeight: 'bold' }} />
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid style={{ display: 'inline-block', verticalAlign: 'super' }}> = </Grid>
                                <Grid style={{ display: 'inline-block' }}>
                                  <Box display="inline-block">
                                    <Typography variant="h3" style={{ textAlign: 'center', padding: '5px 0px 15px 10px' }}>
                                      <sup style={{ fontSize: '1.5em', fontWeight: '400' }}>$</sup>
                                      <CountUp className={classes.counter} separator="," end={lead.buyreward + lead.sellreward} duration={2} />
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                        {brokerDetails.length > 0 && (
                          <Grid className={classes.helperField} style={{ textAlign: 'center', padding: '5%' }}>
                            <Button
                              className={clsx(globalThemeCss.containedBtn, classes.nextButton)}
                              onClick={handleShowmeAgent}
                              variant="contained"
                              size="large"
                              id="show-me-agents"
                            >
                              Show me the Agents
                            </Button>
                            <Button id="select-for-me-brokers-screen" aria-label="Select for me and navigate to Agent Confirmation page" className={clsx(globalThemeCss.textBtn, classes.nextButton)} variant="text" onClick={chooseForMe} size="large">Select for Me</Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {helper && (
              <>
                <Helper helperData={data} helperSwitch={helperHide} />
              </>
            )}
            {selectedProfile ? (
              <>
                <AgentProfile profile={selectedProfile} clear={clearSelectedAgentOption} selectAgent={updatedBasedOnAgentProfile} context={context} title="Agent Profile" />
              </>
            ) : !helper
            && (
              <>
                <div>
                  {!lastStep && (
                    <div className={classes.prev} style={{ justifyContent: 'end' }}>
                      {data && data.showHelperText && (
                        <Typography variant="caption">
                          <div>
                            <Button onClick={helperShow} className={classes.haveQuestions}>
                              <ContactSupportIcon />
                              Have Questions
                            </Button>
                          </div>
                        </Typography>
                      )}
                    </div>
                  )}
                  <Grid>
                    {lastStep ? (
                      <>
                        <AgentConfirmation brokerDetails={selectedAgentOption} changeSelection={clearSelectedRecords} context={context} selectAgent={setSelectedProfile} />
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Paper className={classes.paper}>
                            <Typography variant="h2" className={classes.internalContent}>{currentHeader}</Typography>
                          </Paper>
                        </Grid>
                        <div style={{ width: '5%' }}>
                          <Stepper
                            activeStep={activeStep}
                            connector={(
                              <StepConnector
                                classes={{
                                  line: classes.width30,
                                  lineHorizontal: classes.width30
                                }}
                              />
                            )}
                          >
                            {steps.map((label, index) => {
                              const stepProps = {};
                              const labelProps = {};
                              if (steps.length > 1) {
                                return (
                                  <Step className={classes.paddingZero} key={label} {...stepProps}>
                                    <StepLabel StepIconProps={{"aria-label":`step ${index+1} of ${steps.length}`,"aria-current":selectedAgentOption.length==index?"step":"false"}} className={classes.paddingZero} StepIconComponent={QontoStepIcon} />                                  </Step>
                                );
                              }
                            })}
                            <Button variant='text' disabled={false} aria-label="Select for me and navigate to Agent Confirmation page" id="select-for-me-agents-screen" className={clsx(classes.selectforme, classes.haveQuestions, classes.nextButton)} onClick={chooseForMe}>
                              Select for me
                            </Button>
                          </Stepper>
                        </div>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            overflowY: 'auto', overflowX: 'hidden', paddingBottom: '0', maxHeight: '500px'
                          }}
                        >
                          {((lead.operationType === 'Both' || lead.operationType === 'Selling') && agentDetailsSell.length > 0 && (
                            <>
                              {selectedAgentOption.length < 2 && agentDetailsSell.length > selectedAgentOption.length && (
                                <>
                                  {agentDetailsSell[selectedAgentOption.length].agents.map((agentValue, index) => {
                                    const agentValuePhoto =  agentValue.photo ? DOMPurify.sanitize(agentValue.photo) : DefaultProfileImage;
                                    agentValue.type = agentDetailsSell[selectedAgentOption.length].provides_agents_for;
                                    agentValue.brand_id = agentDetailsSell[selectedAgentOption.length].brand_id;
                                    agentValue.company_id = agentDetailsSell[selectedAgentOption.length].company_id;
                                    agentValue.broker_oid = agentDetailsSell[selectedAgentOption.length].broker_oid;
                                    const imageLoaded = () => {
                                      counter += 1;
                                      if (counter > agentDetailsSell[selectedAgentOption.length].agents.length) {
                                        setLoader(false);
                                      }
                                    };
                                    agentValue.wasAutoSelected = false;
                                    return (
                                      <List className={classes.listSection}>
                                        <ListItem alignItems="flex-start" className={classes.listItemSection} key={`${selectedAgentOption.length}agents${index}`}>
                                          <Grid container>                                           
                                            <Grid container alignItems='center' component={"button"} onClick={e => { updateAgentValue(agentValue); }} xs={10} sm={10} md={10} lg={10} className={classes?.cardButton}>
                                            <Grid container xs={3} sm={3} md={2} lg={2} alignItems="center" justifyContent="center"  style={{ display:  'grid' }}>
                                              {agentValue.isBestMatch &&
                                                <Badge
                                                  badgeContent="BEST MATCH"
                                                  classes={{
                                                    badge: classes.primaryBackgroundColor,
                                                    invisible: classes.badge
                                                  }}
                                                  anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                  }}
                                                  className={clsx(classes.badgeElement, classes.badgeResponsive)}
                                                  invisible={!agentValue.isBestMatch}
                                                >
                                                  <img 
                                                    id={`sell-agent-picture-${index}`} 
                                                    alt={agentValue.agentFName + emptyValue + agentValue.agentLName} 
                                                    style={{ transition: 'opacity .15s linear' }} 
                                                    className={classes.profileImg} 
                                                    src={agentValuePhoto || DefaultProfileImage} 
                                                    srcSet={`${agentValuePhoto || DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`} />
                                                </Badge>
                                              }
                                              {!agentValue.isBestMatch &&
                                                <img 
                                                  id={`sell-agent-picture-${index}`} 
                                                  alt={agentValue.agentFName + emptyValue + agentValue.agentLName} 
                                                  style={{ transition: 'opacity .15s linear' }} 
                                                  className={classes.profileImg} 
                                                  src={agentValuePhoto || DefaultProfileImage} 
                                                  srcSet={`${agentValuePhoto || DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`} />
                                              }
                                            </Grid>
                                            <Grid container xs={9} sm={9} md={8} lg={8} alignItems="flex-start">
                                              <ListItemText
                                                className={classes.secondColumn}
                                                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign:"left" }}
                                                primary={(
                                                  <>
                                                    <Typography id={`sell-agent-name-${index}`} variant="h4">{`${agentValue.agentFName} ${agentValue.agentLName}`}</Typography>
                                                  </>
                                                )}
                                                secondary={(
                                                  <>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="p"
                                                      variant="body2"
                                                      color="#616161"
                                                      id={`sell-agent-heading-${index}`}
                                                    >
                                                    </Typography>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="p"
                                                      variant="body2"
                                                      color="#616161"
                                                      id={`sell-agent-headline-${index}`}
                                                    >
                                                      {agentValue.headline == null ? '' : agentValue.headline}
                                                    </Typography>
                                                  </>
                                                )}
                                                secondaryTypographyProps={{style:{color:'#616161'}}}
                                              />
                                            </Grid>
                                            </Grid>
                                            <Grid container xs={2} sm={2} md={2} lg={2} alignItems="center">
                                              <Button id={`sell-view-profile-${index}`} onClick={(e) => { setSelectedProfile(agentValue); }}>
                                                <span className={classes.viewProfile}>View Profile</span>
                                                <span><ArrowForwardIosIcon className={classes.arrowKeyIcon} /></span>
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </ListItem>
                                      </List>
                                    
                                    );
                                  })}
                                </>
                              )}
                            </>
                          ))}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            overflowY: 'auto', overflowX: 'hidden', paddingBottom: '0', maxHeight: '500px'
                          }}
                        >
                          {((lead.operationType === 'Both' && selectedAgentOption.length === 2) || lead.operationType === 'Buying' || agentDetailsSell.length === selectedAgentOption.length) && agentDetailsBuy.length > 0 && (
                            agentDetailsBuy.map((agentBuy) => (
                              <>
                                {(lead.operationType === 'Both') && (<SEO title={context.bothPages[11].screenTitle} />)}
                                {agentBuy.agents.map((agentValue, index) => {
                                  const agentValuePhoto =  agentValue.photo ? DOMPurify.sanitize(agentValue.photo) : DefaultProfileImage;
                                  agentValue.type = agentBuy.provides_agents_for;
                                  agentValue.brand_id = agentBuy.brand_id;
                                  agentValue.company_id = agentBuy.company_id;
                                  agentValue.broker_oid = agentBuy.broker_oid;
                                  agentValue.wasAutoSelected = false;
                                  const imageLoaded = () => {
                                    counter += 1;
                                    if (counter > agentBuy.agents.length) {
                                      setLoader(false);
                                    }
                                  };
                                  return (
                                    <List className={classes.listSection}>
                                      <ListItem alignItems="flex-start" className={classes.listItemSection} key={`${selectedAgentOption.length}agents${index}`}>
                                        <Grid container>
                                          <Grid container alignItems='center' component={"button"} onClick={e => { updateAgentValue(agentValue); }} xs={10} sm={10} md={10} lg={10}  className={classes?.cardButton}>
                                          <Grid container xs={3} sm={3} md={2} lg={2} alignItems="center" justifyContent="center"  style={{ display:  'grid' }}>
                                            {agentValue.isBestMatch &&
                                              <Badge
                                                badgeContent="BEST MATCH"
                                                classes={{
                                                  badge: classes.primaryBackgroundColor,
                                                  invisible: classes.badge
                                                }}
                                                anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left'
                                                }}
                                                className={clsx(classes.badgeElement, classes.badgeResponsive)}
                                                invisible={!agentValue.isBestMatch}
                                              >
                                                <img 
                                                  id={`buy-agent-picture-${index}`} 
                                                  alt={agentValue.agentFName + emptyValue + agentValue.agentLName} 
                                                  style={{  transition: 'opacity .15s linear' }} 
                                                  className={classes.profileImg} 
                                                  src={agentValuePhoto || DefaultProfileImage} 
                                                  srcSet={`${agentValuePhoto || DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`} 
                                                />
                                              </Badge>
                                            }
                                            {!agentValue.isBestMatch &&
                                              <img id={`buy-agent-picture-${index}`} 
                                                alt={agentValue.agentFName + emptyValue + agentValue.agentLName} 
                                                style={{ transition: 'opacity .15s linear' }} 
                                                className={classes.profileImg} 
                                                src={agentValuePhoto || DefaultProfileImage} 
                                                srcSet={`${agentValuePhoto|| DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`} 
                                              />
                                            }
                                          </Grid>
                                          <Grid container xs={9} sm={9} md={8} lg={8} alignItems="center">
                                            <ListItemText
                                              className={classes.secondColumn}
                                              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',textAlign:"left" }}
                                              primary={(
                                                <>
                                                  <Typography id={`buy-agent-name-${index}`} variant="h4">{`${agentValue.agentFName} ${agentValue.agentLName}`}</Typography>
                                                </>
                                              )}
                                              secondary={(
                                                <>
                                                  <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="p"
                                                    variant="body2"
                                                    color="#616161"
                                                    id={`buy-agent-heading-${index}`}
                                                  >
                                                  </Typography>
                                                  <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="p"
                                                    variant="body2"
                                                    color="#616161"
                                                    id={`buy-agent-headline-${index}`}
                                                  >
                                                    {agentValue.headline == null ? '' : agentValue.headline}
                                                  </Typography>
                                                </>
                                              )}
                                              secondaryTypographyProps={{style:{color:'#616161'}}}
                                            />
                                          </Grid>
                                          </Grid>
                                          <Grid container xs={2} sm={2} md={2} lg={2} alignItems="center">
                                            <Button id={`buy-view-profile-${index}`} onClick={(e) => { setSelectedProfile(agentValue); }}>
                                              <span className={classes.viewProfile}>View Profile</span>
                                              <span><ArrowForwardIosIcon className={classes.arrowKeyIcon} /></span>
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </ListItem>
                                    </List>
                                  );
                                })}
                              </>
                            )))}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                              overflowY: 'auto', overflowX: 'hidden', paddingBottom: '0', maxHeight: '500px'
                            }}
                          >
                            {((((lead.operationType === 'Both') || agentDetailsBoth.length > 0)) && (
                              <>
                                {(lead.buyingLocation.state === lead.sellingLocation.state) && (<SEO title="Choose Agents from List" />)}
                                {agentDetailsBoth.map((agentDetail, indexAgent) => (
                                  <>
                                    {selectedAgentOption.length === indexAgent
                                      && agentDetail.agents.map((agentValue, index) => {
                                        const agentValuePhoto =  agentValue.photo ? DOMPurify.sanitize(agentValue.photo) : DefaultProfileImage;
                                        agentValue.type = agentDetail.provides_agents_for;
                                        agentValue.brand_id = agentDetail.brand_id;
                                        agentValue.company_id = agentDetail.company_id;
                                        agentValue.broker_oid = agentDetail.broker_oid;
                                        agentValue.wasAutoSelected = false;
                                        const imageLoaded = () => {
                                          counter += 1;
                                          if (counter > agentDetailsBoth[selectedAgentOption.length].agents.length) {
                                            setLoader(false);
                                          }
                                        };
                                        return (
                                          <List className={classes.listSection}>
                                            <ListItem alignItems="flex-start" className={classes.listItemSection} key={`${selectedAgentOption.length}agents${index}`}>
                                              <Grid container>
                                                <Grid component="button"  alignItems='center' onClick={e => { updateAgentValue(agentValue); }} container xs={10} sm={10} md={10} lg={10}  className={classes?.cardButton}>
                                                <Grid container xs={3} sm={3} md={2} lg={2} alignItems="center" justifyContent="center" style={{ display:  'grid' }}>
                                                  {agentValue.isBestMatch &&
                                                    <Badge
                                                      badgeContent="BEST MATCH"
                                                      classes={{
                                                        badge: classes.primaryBackgroundColor,
                                                        invisible: classes.badge
                                                      }}
                                                      anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                                      }}
                                                      className={clsx(classes.badgeElement, classes.badgeResponsive)}
                                                      invisible={!agentValue.isBestMatch}
                                                    >
                                                      <img 
                                                        id={`both-agent-picture-${index}`} 
                                                        alt={(agentValue.agentFName + emptyValue + agentValue.agentLName)} 
                                                        style={{ transition: 'opacity .15s linear' }} 
                                                        className={classes.profileImg} src={agentValuePhoto || DefaultProfileImage} 
                                                        srcSet={`${agentValuePhoto || DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`} 
                                                      />
                                                    </Badge>
                                                  }
                                                  {!agentValue.isBestMatch &&
                                                    <img 
                                                      id={`both-agent-picture-${index}`} 
                                                      alt={agentValue.agentFName + emptyValue + agentValue.agentLName} 
                                                      style={{ transition: 'opacity .15s linear' }} 
                                                      className={classes.profileImg} src={agentValuePhoto || DefaultProfileImage} 
                                                      srcSet={`${agentValuePhoto || DefaultProfileImage} 340w,${agentValuePhoto || DefaultProfileImage} 500w,${agentValuePhoto || DefaultProfileImage} 900w`}   
                                                    />
                                                  }
                                                </Grid>
                                                <Grid container xs={9} sm={9} md={8} lg={8} alignItems="flex-start" >
                                                  <ListItemText
                                                    className={classes.secondColumn}
                                                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign:"left" }}
                                                    primary={(
                                                      <>
                                                        <Typography id={`both-agent-name-${index}`} variant="h4">{`${agentValue.agentFName} ${agentValue.agentLName}`}</Typography>
                                                      </>
                                                    )}
                                                    secondary={(
                                                      <>
                                                        <Typography
                                                          sx={{ display: 'inline' }}
                                                          component="p"
                                                          variant="body2"
                                                          color="#616161"
                                                          id={`both-agent-heading-${index}`}
                                                        >
                                                        </Typography>
                                                        <Typography
                                                          sx={{ display: 'inline' }}
                                                          component="p"
                                                          variant="body2"
                                                          color="#616161"
                                                          id={`both-agent-headline-${index}`}
                                                        >
                                                          {agentValue.headline == null ? '' : agentValue.headline}
                                                        </Typography>
                                                      </>
                                                    )}
                                                    secondaryTypographyProps={{style:{color:'#616161'}}}
                                                  />
                                                </Grid>
                                                </Grid>
                                                <Grid container xs={2} sm={2} md={2} lg={2} alignItems="center">
                                                  <Button id={`both-view-profile-${index}`} onClick={(e) => { setSelectedProfile(agentValue); }}>
                                                    <span className={classes.viewProfile}>View Profile</span>
                                                    <span><ArrowForwardIosIcon className={classes.arrowKeyIcon} /></span>
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </ListItem>
                                          </List>
                                        );
                                      })}
                                  </>
                                ))}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}
