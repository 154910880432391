import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { myStyles } from '../signuppopup/styles';
import SEO from '../seo-component/seo';

export default function Loading(props) {
  const classes = myStyles();
  const { description } = props;

  return (
    <Container id="ast-loading" className={classes.root}>
      <SEO title="Loading" />
      <Grid container spacing={6} direction="column" justifyContent="center" alignItems="center" className={classes.loading}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={clsx(classes.paper, classes.customAlign)}>
            <CircularProgress className={classes.loadingIconColor} />
            <Typography variant="h2">{description}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
