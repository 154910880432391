/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles((theme) => ({
  root: () => ({
    fontSize: '1em !important',
    padding: '5px 15px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '500px'
    }
  }),
  iconColor: () => ({
    color: `${theme?.typography?.astProgressbarBackground}`
  })
}));
