/* eslint-disable max-len */
import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { myStyles } from './styles';

const ToolTip = (props) => {
  const classes = myStyles();
  const { description, placement, ariaLabel, name } = props;
  const [expanded, setExpanded] = useState(false);

  const showTooltip = () => {
    setExpanded(true);
  };

  const hideTooltip = () => {
    setExpanded(false);
  };

  const iconId = `tooltip-button-${name}`;

  const toggleTooltip = () => {
    const ariaObj = document.getElementById(iconId);
    if (expanded) {
      hideTooltip();
      ariaObj.setAttribute('aria-expanded', 'false');
    } else {
      showTooltip();
      ariaObj.setAttribute('aria-expanded', 'true');
    }
  };

  return (
    <Tooltip
      id={`tooltip-${name}`}
      aria-label={ariaLabel}
      classes={{ tooltip: classes.root }}
      arrow
      title={description}
      placement={placement}
      enterTouchDelay={0}
      PopperProps={{
        disablePortal: true
      }}
      open={expanded}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <IconButton id={`tooltip-button-${name}`} aria-expanded="false" aria-controls={`tooltip-${name}`} className={classes.iconColor} onClick={toggleTooltip}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ToolTip;
