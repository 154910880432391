/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable arrow-spacing */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import { Typography, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HouseIcon from '@material-ui/icons/House';
import StarBorder from '@material-ui/icons/StarBorder';
import { AdjustIcon, CircleIcon } from '@material-ui/icons';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
//import { index } from 'cheerio/lib/api/traversing';
import { navigate } from 'gatsby-link';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { myStyles } from '../signuppopup/styles';
import '../../styles.scss';
import '../../styles.css';
import { themeStyles } from '../../styles';
import { routerHandling } from './form-validation';
import Helper from './helper';
import ThemeContext from '../../utils/theme-context';
import AwardIcon from '../../images/icon-badge-military-exp.svg';
import SEO from '../seo-component/seo';
import DefaultProfileImage from '../../images/default-agent-image.png';

export default function AgentProfile({ profile, clear, selectAgent, context, title }) {
  const classes = myStyles();
  const themeClass = themeStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [agentProfile, setAgentProfile] = React.useState();
  const agentDescription = profile.about;
  const agentBrokerage = profile.company;
  // const agentSpecilization = profile.propertytype ? profile.propertytype.split(',') : '';
  const agentLanguages = profile.langs;
  const agentLicenses = profile.licenses;
  const { isBestMatch } = profile;

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    setAgentProfile(profile);
  }, [profile]);

  const formatPrice = (n) => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const awards = () => {
    const militaryFamily = Boolean(profile.militaryFamily);
    const militaryService = Boolean(profile.militaryService);
    let awards = [
      { active: militaryFamily, icon: `${AwardIcon}`, label: 'Military Family' },
      { active: militaryService, icon: `${AwardIcon}`, label: 'Military Service' }
    ];

    const result = awards.filter((badge) => badge.active === true);
    return result;
  };

  const agentAwards = awards();

  const specilization = (types) => {
    const property = [];
    // eslint-disable-next-line array-callback-return
    profile.propType.map((item) => {
      // eslint-disable-next-line array-callback-return
      types.filter((id) => {
        if (id.code === item) { property.push(id); }
      });
    });
    return property;
  };

  const agentSpecilization = specilization(context.propertyTypes);

  return profile ? (
    <Container id="agent-profile" style={{ padding: '0px 12px' }} className={classes.root}>
      <SEO title={title} />
      <Grid container style={{ display: 'block', maxWidth: '100%' }}>
        <div className={clsx(classes.buy, classes.profileBuy)}>
          <div className={classes.prev}>
            <div className={classes.backPage}>
              <IconButton aria-label="back" className={classes.backPage} onClick={() => clear(null)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
          </div>
          <br />
          <div className="cmp-agent-profile" style={{ width: '100%', padding: '0px 15px' }}>
            <div className="agent-info-wrapper agent-section-wrapper">
              <div className="img-badge-wrapper">
                <div className="profile-img-wrapper">
                  <img id="agent-profile-picture" className="profile-img" src={profile.photo || DefaultProfileImage} alt={profile.agentFName + ' ' + profile.agentLName} />
                </div>
              </div>
              <div className="info">
                {isBestMatch && (<div id="agent-best-match" className={classes.primaryBackgroundColorOutlined}>Best Match</div>)}
                <h2 className="agent-name">{`${profile.agentFName} ${profile.agentLName}`}</h2>
                {/* <p className="experience-time">{profile.expInYears == null ? '' : `${profile.expInYears} years of experience`}</p> */}
              </div>
              <Button size="large" id="select-agent-top-btn" variant="contained" className={clsx(globalThemeCss.containedBtn, classes.nextButton, classes.fullWidthButton)} onClick={e=> { selectAgent(profile); }}>Select Agent</Button>
            </div>
          </div>
          {agentDescription && (
          <>
            <hr className={classes.hrDivider} />
            <div className="agent-description-wrapper agent-section-wrapper" style={{ padding: '0px 15px' }}>
              <Typography variant="h3">About</Typography>
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: agentDescription }} />
            </div>
          </>
          )}
          {agentBrokerage && (
          <>
            <hr className={classes.hrDivider} />
            <div className={classes.agentProfile}>
              <Typography variant="h3">Brokerage</Typography>
              <div>
                <Typography variant="body2" component="p">{profile.company}</Typography>
                <Typography variant="body2" component="p">{profile.office}</Typography>
              </div>
            </div>
          </>
          )}
          <hr className={classes.hrDivider} />
          <Grid>
            <div className={classes.agentProfile}>
              {agentSpecilization.length > 0 && (
              <div className="agent-description-wrapper agent-section-wrapper">
                <Typography variant="h3">Specialization</Typography>
                <Grid item md>
                  <List>
                    {agentSpecilization.map((item, i) => (
                      <ListItem key={i}>
                        <img className={classes.imageSpec} src={item.icon.file.url} alt={item.label} />
                        <Typography variant="body2" component="p">{item.label}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </div>
              )}
              <div>
                <Grid item md>
                  {agentLanguages.length > 0 && agentLanguages.filter((i) => i !== '').length > 0 && (
                  <div>
                    <Typography variant="h3">Languages</Typography>
                    <List>
                      {agentLanguages.map((item, i) => (
                        <ListItem key={i}>
                          <StarBorder />
                          <Typography style={{ paddingLeft: '12px' }} variant="body2" component="p">{item}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  )}
                  {agentLicenses.length > 0 && agentLicenses.filter((i) => i !== '').length > 0 && (
                  <div>
                    <Typography variant="h3">Licenses</Typography>
                    <List>
                      {agentLicenses.map((item, i) => (
                        <ListItem key={i}>
                          <StarBorder />
                          <Typography style={{ paddingLeft: '12px' }} variant="body2" component="p">{item}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  )}
                </Grid>
              </div>
            </div>
          </Grid>
          {
              agentAwards && agentAwards.length > 0 && (
                <>
                  <hr className={classes.hrDivider} />
                  <div className="agent-awards-wrapper" style={{ maxWidth: '98%', padding: '0px 15px' }}>
                    <Typography variant="h3">Additional Agent Details</Typography>
                    <div className="agent-award-scrollable">
                      <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ whiteSpace: 'nowrap', borderSpacing: '20px', border: '1px' }}>
                        <div className="agent-award-list">
                          {
                            agentAwards.map((item, i) => {
                              return (
                                <Card className={classes.cardListingAwards}>
                                  <CardMedia className={classes.cardContentService} style={{ width: '1.5em', height: '2.6em', position: 'relative', left: '52px', marginTop: '20px', padding: '12px' }} component="svg" role="img" aria-label={`${item.label}`} image={`${item.icon}`} alt={`${item.label} - Award icon`} />
                                  <CardContent style={{ padding: '15px', paddingTop: '15px', paddingBottom: '15px' }}>
                                    <Typography variant="body2" component="p">{`${item.label}`}</Typography>
                                  </CardContent>
                                </Card>
                              );
                            })
                          }
                        </div>
                      </Grid>
                    </div>
                  </div>
                </>
              )
            }
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.agentProfile} style={{ alignSelf: 'center' }}>
            <Paper className={clsx(classes.paper, classes.typoAlign)}>
              <Button id="select-agent-bottom-btn" size="large" variant="contained" onClick={(e) => { selectAgent(profile); }} className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>Select Agent</Button>
            </Paper>
          </Grid>
        </div>
      </Grid>
    </Container>
  ) : null;
}
