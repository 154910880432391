/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import "react-datepicker/dist/react-datepicker.css";
import { CustomProvider } from 'rsuite';
import Radio from '@material-ui/core/Radio';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
//import DateFnsUtils from '@date-io/date-fns';
// import { DateRangePicker, LocalizationProvider, DateRangeDelimiter } from '@material-ui/pickers';
import { addDays, compareAsc, format } from 'date-fns';
import { themeStyles, materialTheme } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import { routerHandling } from './form-validation';
import ThemeContext from '../../utils/theme-context';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import { DateRangePicker, LocalizationProvider } from '@mui/lab';
//import DatePicker from 'react-datepicker'
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import en_US from 'rsuite/locales/en_US'
import "rsuite/dist/rsuite.min.css";
import "./../../datepickerstyles.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';

 export function RangePicker({ value, onChange}) {
   const [error, setError] = useState(false);
    const {beforeToday} = DateRangePicker;
    const oneCalender = useMediaQuery('(max-width:765px)');
    const classes = myStyles();
   return (
     <>
      {!oneCalender? (
     <CustomProvider  locale={en_US} >
      <DateRangePicker 
        value={value}
        id="rangePicker"
        format = 'MM-dd-yyyy'
        // placement='auto'
        cleanable = {false}
        appearance="default"
        placeholder="Start date-End date"
        aria-label="Choose Agent Availability Dates"
        disabledDate={beforeToday()}
        ranges={[]}
        onChange={(newValue) => onChange(newValue)}
      />
      </CustomProvider >)
      :
      (
        <CustomProvider locale={en_US} >
      <DateRangePicker 
        value={value}
        format = 'MM-dd-yyyy'
        // placement='auto'
        showOneCalendar
        cleanable = {false}
        appearance="default"
        placeholder="Start date-End date"
        disabledDate={beforeToday()}
        ranges={[]}
        onChange={(newValue) => onChange(newValue)}
      />
      </CustomProvider >
      )}
      <div id="instructions" className={classes.sronly} >Date format MM/DD/YYYY</div>

</>
   );
 }

export default function SpecificDates({ data, context }) {
  const classes = myStyles();
  const pagerRef = useRef(null);
  const [lead, setLead] = useState('');
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const themeClass = themeStyles();
  const [selectedValue, setSelectedValue] = React.useState('no');
  const [dates, setDates] = React.useState([null, null]);
  const [helper, setHelper] = useState(false);

  const onDateChange = (value) => {
    setDates(value);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
    if (value === 'no') {
      setDates([null, null]);
    }
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
  }, []);

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };
  useEffect (()=>{
    if(pagerRef.current){
      const obj = document?.getElementById('rangePicker');
      obj?.setAttribute('aria-describedby','instructions');
    }
  })
  useEffect(() => {
    if (lead) {
      if (lead.isSpecificDates) {
        if (lead.isSpecificDates.value) {
          setSelectedValue(lead.isSpecificDates.value);
          if (lead.isSpecificDates.value === 'yes') {
            dates[0] = new Date(lead.isSpecificDates.dates[0])
            dates[1] = new Date(lead.isSpecificDates.dates[1])
          }
        }
      }
    }
  }, [lead]);

  const checkValidation = () => {
    let flag = true;
    if (selectedValue === 'no') {
      flag = false;
    } else {
      if(dates&& (dates[0] && dates[1])) {
        flag = false;
      }
    }
    return flag;
  };

  const handleClick = (path) => {
    if (selectedValue === 'yes') {
      dates[0] = new Date(format(new Date(dates[0]), 'MM/dd/yyyy 00:00:00')).toISOString();
      dates[1] = new Date(format(new Date(dates[1]), 'MM/dd/yyyy 00:00:00')).toISOString();
    }
    dispatch({
      type: 'SET_SPECIFIC_DATES',
      value: {
        ...lead,
        isSpecificDates: {
          value: selectedValue, dates
        }
      }
    });
    navigate(path);
  };

  const closeModal = () => {
    localStorage.removeItem('lead');
    navigate('/');
  };
  const prevNavigation=() =>{
    const prevBtnUrl = routerHandling(context, "prev");
    navigate(`${prevBtnUrl}`)
  };
  return (
    <Container id="specific-dates" className={classes.root}>
      <Grid container>
        {helper && (
        <>
          <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
        </>
        )}
        {!helper
      && (
        <>
          <div className={classes.root}>
            <div className={classes.prev}>
              <IconButton aria-label="back" onClick={prevNavigation} className={classes.backPage}>
                <ArrowBackIcon />
              </IconButton>
              {data && data.showHelperText && (
              <Typography variant="caption">
                <div>
                  <Button onClick={helperShow} className={classes.haveQuestions}>
                    <ContactSupportIcon />
                    Have Questions
                  </Button>
                </div>
              </Typography>
              )}
              {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                <CloseIcon />
              </IconButton> */}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <Typography variant="h2" className={classes.internalContent}>{data.question.question}</Typography>
              </Paper>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" spacing={3} style={{ textAlign: 'center', margin: '0px' }}>
              <Grid item xs={12} sm={5} md={4} lg={4}>
                <Box display="flex" alignItems="center" padding="5px" cursor="pointer" border="1px solid" onClick={() => handleChange('no')}>
                  <Typography component="div" style={{ marginBottom: '0px' }}>
                    <Radio
                      id="specific-dates-no-btn"
                      color="black"
                      checked={selectedValue === 'no'}
                      value="no"
                      name="radio-button-demo"
                    />
                  </Typography>
                  <label htmlFor='specific-dates-no-btn'>
                    <Typography component="div" style={{ marginBottom: '0px' }}>No, I'm flexible</Typography>
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4}>
                <Box display="flex" alignItems="center" padding="5px" cursor="pointer" border="1px solid" onClick={() => handleChange('yes')}>
                  <Typography component="div" style={{ marginBottom: '0px' }}>
                    <Radio
                      id="specific-dates-yes-btn"
                      color="black"
                      checked={selectedValue === 'yes'}
                      value="yes"
                      name="radio-button-demo"
                    />
                  </Typography>
                  <label htmlFor='specific-dates-yes-btn'>
                    <Typography component="div" style={{ marginBottom: '0px' }}>Yes, I have specific dates</Typography>
                  </label>
                </Box>
              </Grid>
              {selectedValue === 'yes'
              && (
                <Grid ref={pagerRef} xs={12} sm ={12} md={12} lg={12} item>
                   <label htmlFor="rangePicker">
                     <Typography>Choose Agent Availibility Dates </Typography>
                   </label>
                   <RangePicker value={dates} onChange={onDateChange} /> 
                  
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={clsx(classes.paper, classes.typoAlign, classes.customPaddingBtm)}>
                  <Button
                    id="specific-dates-next-btn"
                    variant="contained"
                    size="large"
                    className={clsx(selectedValue && globalThemeCss.containedBtn, classes.nextButton)}
                    onClick={() => handleClick(routerHandling(context, 'next'))}
                    disabled={checkValidation()}
                  >
                    Next
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      </Grid>
    </Container>
  );
}
