/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useEffect } from 'react';
import {
  Grid, Dialog, DialogContent
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby-link';
import clsx from 'clsx';
import { myStyles } from '../signuppopup/styles';
import '../../styles.scss';
import Map from '../static-map/map';
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import IconButton from '@material-ui/core/IconButton';
import { routerHandling } from './form-validation';

export default function SellingLocation({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [helper, setHelper] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [mesDialogOpen, setMesDialogOpen] = useState(false);
  const [lead, setLead] = useState('');
  const [openSellPopup, setopenSellPopup] = useState(false);
  const [changeSellLoc, setChangeSellLoc] = useState(false);

  const getLeadDetails = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    return getLead;
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const value = getLeadDetails();
    setNextDisabled(!value?.sellingLocation?.selectedOption);
    // Create a new function to get a lead and call it in use effect and set place
    // if (getLead?.sellingLocation?.state === 'IA') {
    //   setopenSellPopup(!openSellPopup);
    //   setChangeSellLoc(!changeSellLoc);
    // }
  }, []);

  const setPlace = (location) => {
    if (location?.state === 'IA') {
      if (lead.operationType === 'Selling') {
        setopenSellPopup(!openSellPopup);
        setMesDialogOpen(mesDialogOpen);
        setChangeSellLoc(changeSellLoc);
      } else {
        setopenSellPopup(openSellPopup);
        setMesDialogOpen(!mesDialogOpen);
        setChangeSellLoc(changeSellLoc);
      }
    } else {
      setMesDialogOpen(mesDialogOpen);
      setopenSellPopup(openSellPopup);
    }
    if (typeof location === 'object' && Object.keys(location).length > 0) {
      dispatch({
        type: 'SET_SELL_LOCATION',
        value: {
          ...lead,
          sellingLocation: {
            ...location
          }
        }
      });
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  };

  const handlerClick = (path) => {
    const value = getLeadDetails();
    if (value?.sellingLocation && value?.sellingLocation?.state === 'IA') {
      setMesDialogOpen(true);
    } else {
      setMesDialogOpen(false);
      navigate(path);
    }
  };
  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  const handleClose = () => {
    setopenSellPopup(!openSellPopup);
    setNextDisabled(true);
    setChangeSellLoc(!changeSellLoc);
    dispatch({
      type: 'SET_SELL_LOCATION',
      value: {
        ...lead,
        sellingLocation: {
        }
      }
    });
  };

  const handleBuying = () => {
    navigate('/ast/both/buy-location');
  };

  const prevNavigation=() =>{
    const prevBtnUrl = routerHandling(context, "prev");
    navigate(`${prevBtnUrl}`)
  };

  return (
    <Container id="ast-sell-location" className={classes.root}>
      <Grid container>
        {helper && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {!helper
          && (
            <>
              <div className={classes.buy}>
                <div className={classes.prev}>
                <IconButton aria-label="back" onClick={prevNavigation} className={classes.backPage}>
                  <ArrowBackIcon />
                </IconButton>
                  <Typography variant="caption">
                    <div>
                      <Button onClick={helperShow} className={classes.haveQuestions}>
                        <ContactSupportIcon />
                        Have Questions
                      </Button>
                    </div>
                  </Typography>
                  {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                <CloseIcon />
              </IconButton> */}
                </div>
                {/* <Grid spacing={3} container direction="column" justifyContent="center" alignItems="center"> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                    {/* <Typography className={classes.internalSubText}>{data.helperText.internal.content}</Typography> */}
                  </Paper>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <Paper className="mainWrapper">
                    {/* {lead && <Map fromSell place={setPlace} mesDialogOpen={mesDialogOpen} value={lead?.sellingLocation?.selectedOption} required mapView="sell" />} */}
                    {lead && !changeSellLoc
                      && (
                        <Map fromSell value={lead?.sellingLocation?.selectedOption} place={setPlace} required mapView="sell" />
                      )}
                    {changeSellLoc
                      && (
                        // <Map fromSell place={setPlace} value={lead?.sellingLocation?.selectedOption} required mapView="sell" />
                        <Map fromSell value={null} place={setPlace} required mapView="sell" />
                      )}
                  </Paper>
                </Grid>
                {!mesDialogOpen && !openSellPopup
                  && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Paper className={clsx(classes.paper, classes.typoAlign, classes.customMarginTop)}>
                        <Button id="sell-location-next-btn" disabled={nextDisabled} onClick={() => handlerClick(routerHandling(context, 'next'))} size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained">Next</Button>
                      </Paper>
                    </Grid>
                  )}
                {/* </Grid> */}
              </div>
              {openSellPopup
                && (
                  <Dialog
                    open={openSellPopup}
                    onClose={(_, reason) => { if (reason !== 'backdropClick') { handleClose; } }}
                    style={{ height: 'auto' }}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    aria-label="Service unavailable"
                  >
                    <DialogContent id="alert-dialog-description">
                      {/* <Grid className={classes.dialogClose}>
                        <IconButton aria-label="close" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid> */}
                      <Grid container justifyContent="center" alignItems="center">
                        <Typography style={{ textAlign: 'center' }}>We noticed that you're searching for a home in Iowa.</Typography>
                      </Grid>
                      <Grid container justifyContent="center" alignItems="center">
                        <Typography>
                          Service is unavailable in this state.
                        </Typography>
                      </Grid>
                      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} onClick={handleClose}>
                          Close
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
              {mesDialogOpen
                && (
                  <Dialog
                    open={mesDialogOpen}
                    onClose={(_, reason) => { if (reason !== 'backdropClick') { handleClose; } }}
                    style={{ height: 'auto' }}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    aria-label="Service unavailable"
                  >
                    <DialogContent id="alert-dialog-description">
                      <Grid container justify="center" xs={12} sm={12} md={12} lg={12}>
                        <Typography justify="center">
                          Service is unavailable in this state.
                        </Typography>
                        <Typography justify="center">
                          We can still help you with purchasing
                        </Typography>
                        <Typography justify="center">
                          a home in a different state.
                        </Typography>
                      </Grid>
                      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button id="continue-buying-btn" variant="contained" size="large" onClick={handleBuying} className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                          Continue to Buying
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
            </>
          )}
      </Grid>
    </Container>
  );
}
