/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import Header from '../components/header';
import AstContextProvider from '../utils/ast-context';
import { myStyles, BorderLinearProgress } from '../components/signuppopup/styles';
import DynamicAst from '../utils/dynamic-ast';
import ThemeContext from '../utils/theme-context';
import { customeTheme } from '../styles';
import { getLocalData, urlValidation } from '../components/ast-components/form-validation';
import CustomFonts from '../components/customFont/customfonts';
import SEO from '../components/seo-component/seo';

export default function AstPage({ pageContext, data }) {
  const classes = myStyles(pageContext);
  const [partnerTheme] = useState(pageContext.theme);
  const navigation = pageContext?.appData?.navigation;
  const lastScreen = pageContext.lastScreen || false;
  const isBrowser = typeof localStorage !== 'undefined';
  const getLead = isBrowser ? JSON.parse(localStorage.getItem('lead')) : null;
  const [pageLength, setPageLength] = useState(getLead?.operationType === 'Both' ? pageContext.bothPages.length : getLead?.operationType === 'Selling' ? pageContext.sellPages.length : getLead?.operationType === 'Buying' ? pageContext.buyPages.length : 10);
  const value = ((pageContext.pageIndex) / (pageLength + 1)) * 100;
  // useEffect(() => {
  //   const getLead = JSON.parse(localStorage.getItem('lead'));
  //   const pagevalue = getLead?.operationType === 'Both' ? pageContext.bothPages.length : getLead?.operationType === 'Selling' ? pageContext.sellPages.length : getLead?.operationType === 'Buying' ? pageContext.buyPages.length : 10;
  //   if (getLead && pageContext && pageLength !== pagevalue) {
  //     localStorage.setItem('pageLength', pagevalue);
  //     setPageLength(pagevalue);
  //   }
  // }, []);

  return (
    <>
      <a href="#main" className={classes.skipLink}>Skip to main content</a>
      {/* <div id="main" className={classes.main} tabIndex="-1" role="main"> */}
      <CustomFonts data={pageContext?.theme?.typography} />
      <SEO title={pageContext?.screenTitle} />
      <ThemeContext.Provider value={partnerTheme}>
        <ThemeProvider theme={customeTheme(partnerTheme)}>
          <Header navigation={navigation} isLeadForm="true" isLastScreen={lastScreen} />
          <main id="main" className={classes.main} tabIndex="-1">
            <Grid className={clsx(classes.root, classes.progressBar)}>
              {(!pageContext.next && !pageContext.previous)
                ? (
                  <BorderLinearProgress aria-busy="false" aria-label="Sign up Lead Form" variant="determinate" value="100" />
                ) : (
                  <BorderLinearProgress aria-busy="false" aria-label="Sign up Lead Form" variant="determinate" value={value} />
                )}
              {/* <BorderLinearProgress variant="determinate" value={(pageContext.pageIndex + 1) / pageLength * 100} /> */}
            </Grid>
            <Container style={{ marginTop: '110px', padding: '0' }}>
              <AstContextProvider>
                {DynamicAst(pageContext.screenType, data.contentfulModifiedScreenContent, pageContext)}
              </AstContextProvider>
            </Container>
          </main>
        </ThemeProvider>
      </ThemeContext.Provider>
      {/* </div> */}
    </>
  );
}

export const query = graphql`
  query($contentful_id: String) {
    contentfulModifiedScreenContent(contentful_id: {eq: $contentful_id}) {
      id
      entryTitle
      screenType
      slug
      question {
        internal {
          content
        }
      }
      questionSubtext
      helperLabel
      helperText {
        internal {
          content
        }
      }
      showHelperText
      screenCode
      screenIndex
      screenTitle
      screenEnabled
      isThisScreenRequiredForOperationTypeBoth
      isThisScreenRequiredForOperationTypeSell
      isThisScreenRequiredForOperationTypeBuy
      bodyHelperText
      question {
        question
      }
      questionForBothType{
        questionForBothType
      }
      optInToTextingText {
        raw
      }
      showMemberField
      memberFieldLabel
      memberFieldHelperText {
        raw
      }
      residenceLegalDisclaimer {
        raw
      }
      backgroundColor
    }
  }
`;
